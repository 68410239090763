body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
body::-webkit-scrollbar-track {
	margin-top: 2px;
	margin-bottom: 2px;
}
body::-webkit-scrollbar-thumb {
	border-radius: 9px;
}

body.chakra-ui-light::-webkit-scrollbar-track {
	background: #f5f5f5;
}
body.chakra-ui-light::-webkit-scrollbar-thumb {
	background-color: #5d528c;
}

body.chakra-ui-dark::-webkit-scrollbar-track {
	background: #0d122f;
}
body.chakra-ui-dark::-webkit-scrollbar-thumb {
	background-color: #626784;
}
.scroll_playlist::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
.scroll_playlist::-webkit-scrollbar-track {
	margin-top: 10px;
	margin-bottom: 10px;
}
.scroll_playlist::-webkit-scrollbar-thumb {
	border-radius: 9px;
}
[data-theme='light'] .scroll_playlist::-webkit-scrollbar-track {
	background: #f5f5f5;
}
[data-theme='light'] .scroll_playlist::-webkit-scrollbar-thumb {
	background-color: #5d528c;
}
[data-theme='dark'] .scroll_playlist::-webkit-scrollbar-track {
	background: #0d122f;
}
[data-theme='dark'] .scroll_playlist::-webkit-scrollbar-thumb {
	background-color: #626784;
}
